import { getCookie, setCookie } from "./cookies";
import {
  GF_IS_US_IP,
  GF_POPUP_NOT_SHOW,
  GF_POPUP_SHOWN_IN_DAY,
  GF_POPUP_SHOWN_IN_MONTH,
  GF_POPUP_SHOWN_IN_WEEK,
  GF_ROLLOUT_NUMBER,
  GF_SESSION_VIEW,
  IPAPI_URL
} from "./constants";
import { getTimeToMonthlyExpiry, getTimeToWeeklyExpiry, manageCookieExpiry } from "../services/expiryManagement";

declare const TESTING_BYPASS: string;
declare const IPAPI_TOKEN: string;

export const hourInMS = 1000 * 60 * 60;
export const dayInMS = hourInMS * 24;
export const expirationMaxTime = dayInMS * 400;

// Bypass all filters if cookies are not enabled or TESTING_BYPASS is true
export const isFilterBypassed = () => {
  const areCookiesEnabled = window.navigator.cookieEnabled;
  return !areCookiesEnabled || TESTING_BYPASS || !window.sessionStorage || !window.localStorage;
};

// Return if the page is not the home page
export const isCorrectPage = (pageMatch: string) => {
  const regex = new RegExp(pageMatch, "i");
  return regex.test(window.location.href);
};

// Return if the browser is not Chrome based
export const isChromeBased = () =>
  /Chrome|Edg|OPR|Brave/i.test(window.navigator.userAgent);

// Return if the user is on mobile
export const isMobile = () => /Mobi|Android/i.test(window.navigator.userAgent);

// Return if the user clicked Don't show again
export const wasNotShowAgainClicked = (): boolean =>
  getCookie(GF_POPUP_NOT_SHOW) as boolean;

// Return if the popup was shown today
export const wasShownInDay = (): boolean =>
  getCookie(GF_POPUP_SHOWN_IN_DAY) as boolean;

// Return if the popup was shown n times in the last week
export const wasShownInWeek = (): number =>
  getCookie(GF_POPUP_SHOWN_IN_WEEK) as number;

// Return if the popup was shown n times in the last month
export const wasShownInMonth = (): number =>
  getCookie(GF_POPUP_SHOWN_IN_MONTH) as number;

export const markAsShownInDay = (hoursToShow: number) => {
  const dailyExpiration = hourInMS * hoursToShow;
  setCookie(GF_POPUP_SHOWN_IN_DAY, true, dailyExpiration);
};
export const markAsShownInWeek = (shownInWeek: number) => {
  const weeklyExpiration = getTimeToWeeklyExpiry();
  setCookie(GF_POPUP_SHOWN_IN_WEEK, shownInWeek + 1, weeklyExpiration);
};
export const markAsShownInMonth = (shownInMonth: number) => {
  const monthlyExpiration = getTimeToMonthlyExpiry();
  setCookie(GF_POPUP_SHOWN_IN_MONTH, shownInMonth + 1, monthlyExpiration);
};

export const markVisitor = (
  hoursToShow: number,
  shownInWeek: number,
  shownInMonth: number
) => {
  manageCookieExpiry();
  markAsShownInDay(hoursToShow);
  markAsShownInWeek(shownInWeek);
  markAsShownInMonth(shownInMonth);
};

// Return if the user is not in the rollout percentage
export const isInRollOut = (rolloutPercentage: number) => {
  let rollOutNumber = getCookie(GF_ROLLOUT_NUMBER) as number | null;

  // Check if the cookie value is a valid number.
  if (rollOutNumber === null) {
    // Generate a new number if the cookie value is not a valid number.
    rollOutNumber = Math.floor(Math.random() * 100);
    setCookie(GF_ROLLOUT_NUMBER, rollOutNumber.toString(), expirationMaxTime);
  }
  const isInRollOutResult = rollOutNumber < rolloutPercentage;
  return isInRollOutResult;
};

// Return if the user IP is not from US
export const isUsIp = async (country: string = "US") => {
  const isUsIpCookie = getCookie(GF_IS_US_IP);
  if (isUsIpCookie !== null) return isUsIpCookie;
  try {
    const fetchURL = `${IPAPI_URL}?key=${IPAPI_TOKEN}`;
    const response = await fetch(fetchURL);
    const data = await response.json();
    const isUsIsoCode = data.countryCode === country;
    const monthlyExpiration = dayInMS * 30;
    setCookie(GF_IS_US_IP, isUsIsoCode, monthlyExpiration);
    return isUsIsoCode;
  } catch (error) {
    console.error("Failed to fetch GeoIP service:", error);
    return false;
  }
};

export const isFirstSessionView = () => {
  const sessionView = window.sessionStorage.getItem(GF_SESSION_VIEW);
  return sessionView === null;
};

export const setFirstSessionView = () => {
  window.sessionStorage.setItem(GF_SESSION_VIEW, "true");
};

