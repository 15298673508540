import type { Analytics } from "./analytics";

export const getConfig = async (
  configURL: string,
  defaultConfig: Config,
  analytics?: Analytics
) => {
  try {
    const response = await fetch(configURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors"
      }
    });
    return await response.json();
  } catch (error) {
    console.error("Failed to fetch config file:", error);
    analytics?.onFailToLoadConfig({error});
    return defaultConfig;
  }
};
