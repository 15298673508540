export const getCookie = (name: string) => {
  const cookieArr: string[] = document.cookie.split("; ");
  const cookieFound = cookieArr.find((cookie) => cookie.startsWith(`${name}=`));
  if (!cookieFound) return null;
  const cookieValue = cookieFound.split("=")[1];
  if (cookieValue === "true") return true;
  if (cookieValue === "false") return false;
  if (!isNaN(Number(cookieValue))) return Number(cookieValue);
  return decodeURIComponent(cookieValue);
};

export const setCookie = (
  name: string,
  value: string | number | boolean,
  expiration: number
) => {
  const expirationTime = new Date(Date.now() + expiration);
  const expires = "; expires=" + expirationTime.toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)} ${expires}; path=/`;
};
