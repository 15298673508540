import { type AnalyticsUrlsRecord } from "../services/analytics";
import { GF_POPUP_NOT_SHOW } from "../utils/constants";
import { setCookie } from "../utils/cookies";
import { createPopup } from "../utils/popup";
import { setButton, setElement } from "../utils/renders";
import { dayInMS } from "../utils/verifications";

const configUrl = "https://cdn.givefreely.com/popup/sefaria/config.json";
const defaultConfig: Config = {
  isOn: true,
  hoursToShow: 24,
  repeatInAWeek: 3,
  repeatInAMonth: 6,
  installLink: "https://gvfr.ly/4biX4Xq",
  questionnaireLink: "",
  millisecondsDelayedBeforePop: 15000,
  rolloutPercentage: 10,
  pageMatch:
    "^(https?:\\/\\/)?([a-zA-Z0-9-]+\\.)*sefaria\\.org(?:\\/|\\/?\\?[^ ]*)?$",
  wording: {
    header: "",
    title:
      "Help Build the Future of Jewish Learning - for Free - with Give Freely",
    copy: "Give Freely is a browser extension that automatically applies coupons at over 10,000 different stores to save you money, and the stores donate to Sefaria with every purchase. It's free to use, safe and installs quickly.",
    footer: "Try it now and generate an instant $10 donation to us!",
    cta: "Learn more and earn Sefaria an instant $10 donation",
    openFormBtn: "",
    closeBtn: "",
    notShowBtn: ""
  }
};

const analyticsUrls: AnalyticsUrlsRecord = {
  onClose: "https://gvfr.ly/44l0u9I",
  onOpen: "https://gvfr.ly/3WmJCxf",
  onFailToLoadConfig: "https://gvfr.ly/3UDProN",
  onLoad: "https://gvfr.ly/3JF5Tif",
  onActivate: "",
  onInstallClick: ""
};

const analyticsEventId = "sefaria";

createPopup(
  configUrl,
  defaultConfig,
  analyticsUrls,
  analyticsEventId,
  (config, container, analytics) => {
    setElement("gf-header", config.wording.header);
    setElement("gf-footer", config.wording.footer);
    setElement(
      "gf-open-form-btn",
      config.wording.openFormBtn,
      config.questionnaireLink
    );
    setButton("gf-no-thanks-btn", config.wording.closeBtn, () => {
      analytics.onClose();
      container.remove();
    });
    setButton("gf-not-show-btn", config.wording.notShowBtn, () => {
      analytics.onPermanentClose();
      const expirationMaxTime = dayInMS * 400;
      setCookie(GF_POPUP_NOT_SHOW, true, expirationMaxTime);
      container.remove();
    });
  }
);
